import { Button, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Setting from "./Setting";
import { basicSettings, advancedSettings } from "./data";
import { db } from "../../firebase/app";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function Settings({ user, setUser }) {
  const navigate = useNavigate();
  // We don't show the advanced settings by default
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  user?.basicSettings.openJobsStrategy &&
    console.log("User Settings: ", user.basicSettings.openJobsStrategy);
  console.log("Type of User: ", typeof user);
  console.log("User: ", user);

  // Function to update the user settings in the db
  const updateSettings = (settingsType) => {
    if (!user) {
      alert("Please Login first before saving any settings.");
      // Redirecting to the Login page
      navigate("/account");
      return;
    }

    // Getting a reference for users' collection
    const usersCollection = collection(db, "users");

    // Create a query to search for documents where the userId field matches the provided value
    const q = query(usersCollection, where("userId", "==", user.userId));

    return getDocs(q)
      .then((querySnapshot) => {
        // Check if any documents match the query
        if (!querySnapshot.empty) {
          // Get the first matching document
          const userDoc = querySnapshot.docs[0];
          const userRef = doc(db, "users", userDoc.id); // Reference to the user document

          // We update the basic settings
          if (settingsType === "basic") {
            // Get the current settings object
            const currentSettings = userDoc.data().basicSettings || {};

            // Merge the new settings with the current settings
            const updatedSettings = {
              ...currentSettings,
              openJobsStrategy: user?.basicSettings.openJobsStrategy,
              playAudio: user?.basicSettings.playAudio,
              refreshAfterMin: user?.basicSettings.refreshAfterMin,
              refreshAfterMax: user?.basicSettings.refreshAfterMax,
              scrapFirstPagePercentage:
                user?.basicSettings.scrapFirstPagePercentage,
            };

            // Update the settings object within the user document
            return updateDoc(userRef, { basicSettings: updatedSettings })
              .then(() => {
                console.log("Basic settings updated successfully");
                alert("Basic settings updated successfully");
              })
              .catch((error) => {
                console.error("Error updating basic settings:", error);
                alert("Error updating basic settings:", error);
              });
          } else {
            // We update the advanced settings
            // Get the current settings object
            const currentSettings = userDoc.data().advancedSettings || {};

            // Merge the new settings with the current settings
            const updatedSettings = {
              ...currentSettings,
              maxNoOfJobs: user?.advancedSettings.maxNoOfJobs,
              minNoOfJobs: user?.advancedSettings.minNoOfJobs,
              scrapSecondPagePercentage:
                user?.advancedSettings.scrapSecondPagePercentage,
              startOpeningJobs: user?.advancedSettings.startOpeningJobs,
            };

            // Update the settings object within the user document
            return updateDoc(userRef, { advancedSettings: updatedSettings })
              .then(() => {
                console.log("Advanced settings updated successfully");
                alert("Advanced settings updated successfully");
              })
              .catch((error) => {
                console.error("Error updating advanced settings:", error);
                alert("Error updating advanced settings:", error);
              });
          }
        } else {
          console.log("User document not found");
          alert("User document not found");
          return null;
        }
      })
      .catch((error) => {
        console.error("Error fetching user document:", error);
        alert("Error fetching user document:", error);
        // Handle error
      });
  };

  // We make sure that refreshAfterMin is always smaller than refreshAfterMax
  useEffect(() => {
    // If refresh after min is greater than max
    if (
      user?.basicSettings.refreshAfterMin >= user?.basicSettings.refreshAfterMax
    ) {
      // We swap the values
      setUser((prevState) => {
        return {
          ...prevState,
          basicSettings: {
            ...prevState.basicSettings,
            refreshAfterMin: user?.basicSettings.refreshAfterMax,
            refreshAfterMax: user?.basicSettings.refreshAfterMin + 1,
          },
        };
      });
    }
  }, [
    user?.basicSettings.refreshAfterMin,
    user?.basicSettings.refreshAfterMax,
  ]);

  // Handling settings value update
  const handleSettingUpdate = (
    updateType,
    id,
    payload,
    settingMin,
    settingMax,
    options,
    type
  ) => {
    // Handling setting increment (BASIC SETTINGS)
    if (updateType === "increment" && type == "basic") {
      // Setting the value to max allowable value
      if (user?.basicSettings[id] + payload >= settingMax) {
        setUser((prevData) => {
          return {
            ...prevData,
            basicSettings: {
              ...prevData.basicSettings,
              // Updatig our item of interest
              [id]: settingMax,
            },
          };
        });
      } else {
        setUser((prevData) => {
          return {
            ...prevData,
            basicSettings: {
              ...prevData.basicSettings,
              // Updatig our item of interest
              [id]: prevData.basicSettings[id] + payload,
            },
          };
        });
      }
    }

    if (updateType === "increment" && type == "advanced") {
      // Handling setting increment (ADVANCED SETTINGS)
      // Setting the value to max allowable value
      if (user?.advancedSettings[id] + payload >= settingMax) {
        setUser((prevData) => {
          return {
            ...prevData,
            advancedSettings: {
              ...prevData.advancedSettings,
              // Updatig our item of interest
              [id]: settingMax,
            },
          };
        });
      } else {
        setUser((prevData) => {
          return {
            ...prevData,
            advancedSettings: {
              ...prevData.advancedSettings,
              // Updatig our item of interest
              [id]: prevData.advancedSettings[id] + payload,
            },
          };
        });
      }
    }

    // Handling setting decrement (BASIC SETTINGS)
    if (updateType === "decrement" && type == "basic") {
      // Setting the value to min allowable value
      if (user?.basicSettings[id] - payload <= settingMin) {
        setUser((prevData) => {
          return {
            ...prevData,
            basicSettings: {
              ...prevData.basicSettings,
              // Updatig our item of interest
              [id]: settingMin,
            },
          };
        });
      } else {
        setUser((prevData) => {
          return {
            ...prevData,
            basicSettings: {
              ...prevData.basicSettings,
              // Updatig our item of interest
              [id]: prevData.basicSettings[id] - payload,
            },
          };
        });
      }
    }

    if (updateType === "decrement" && type == "advanced") {
      // Handling setting decrement (ADVANCED SETTINGS)
      // Setting the value to min allowable value
      if (user?.advancedSettings[id] - payload <= settingMin) {
        setUser((prevData) => {
          return {
            ...prevData,
            advancedSettings: {
              ...prevData.advancedSettings,
              // Updatig our item of interest
              [id]: settingMin,
            },
          };
        });
      } else {
        setUser((prevData) => {
          return {
            ...prevData,
            advancedSettings: {
              ...prevData.advancedSettings,
              // Updatig our item of interest
              [id]: prevData.advancedSettings[id] - payload,
            },
          };
        });
      }
    }

    // Handling non-number settings. They happen to be all basic Settings, so here we go.
    if (id === "openJobsStrategy" || id === "playAudio") {
      if (updateType === "increment") {
        setUser((prevData) => {
          return {
            ...prevData,
            basicSettings: {
              ...prevData.basicSettings,
              // Updating our item of interest
              [id]: options[1],
            },
          };
        });
      } else {
        setUser((prevData) => {
          return {
            ...prevData,
            basicSettings: {
              ...prevData.basicSettings,
              // Updating our item of interest
              [id]: options[0],
            },
          };
        });
      }
    }
  };

  // Basic Settings components
  const BasicSettingsComponents = basicSettings.map((basicSetting) => {
    return (
      <Setting
        user={user}
        setUser={setUser}
        key={basicSetting.id}
        setting={basicSetting}
        handleSettingUpdate={handleSettingUpdate}
      />
    );
  });

  // Advanced Settings components
  const AdvancedSettingsComponents = advancedSettings.map((advancedSetting) => {
    return (
      <Setting
        user={user}
        setUser={setUser}
        key={advancedSetting.id}
        setting={advancedSetting}
        handleSettingUpdate={handleSettingUpdate}
      />
    );
  });

  return (
    <div className="px-1 md:px-4">
      <Card>
        <div className="px-2 py-2">
          {/* Basic Settings */}
          <div>
            <div className="pb-4 flex space-x-2" id="basic">
              <SettingsIcon />
              <div className="font-bold">Basic Settings</div>
            </div>
            {/* Restore To Default Settings */}
            <Button variant="outlined">
              Restore Basic Settings To Default
            </Button>
            {/* Basic Settings */}
            <div className="pt-4">
              <Card elevation={4} sx={{ py: "16px" }}>
                {BasicSettingsComponents}
              </Card>
            </div>
          </div>

          {/* Save Basic Settings */}
          <div className="mt-4">
            <Button variant="contained" onClick={() => updateSettings("basic")}>
              Save Basic Settings
            </Button>
          </div>

          {/* Advanced Settings */}
          {/* We only show these settings after user has clicked on show advanced settings button */}
          {showAdvancedSettings && (
            <div className="mt-8" id="advanced">
              <div className="pb-4 flex space-x-2" id="basic">
                <SettingsIcon />
                <div className="font-bold">Advanced Settings</div>
              </div>

              {/* Restore To Default Settings */}
              <Button variant="outlined">
                Restore Advanced Settings To Default
              </Button>
              {/* Advanced Settings */}
              <div className="pt-4">
                <Card elevation={4} sx={{ py: "16px" }}>
                  {AdvancedSettingsComponents}
                </Card>
              </div>
            </div>
          )}

          {/* Save Basic Settings */}
          {showAdvancedSettings && (
            <div className="mt-4">
              <Button
                variant="contained"
                onClick={() => updateSettings("advanced")}
              >
                Save Advanced Settings
              </Button>
            </div>
          )}

          {/* Show Advanced Settings Button */}
          <div className="mt-4">
            <Button
              variant="outlined"
              onClick={() => {
                setShowAdvancedSettings((prevValue) => !prevValue);
              }}
            >
              {showAdvancedSettings
                ? "Hide Advanced Settings"
                : "Show Advanced Settings"}
            </Button>
          </div>

          {showAdvancedSettings && (
            // Restore All Settings to Default
            <div className="mt-4">
              <Button
                variant="outlined"
                onClick={() => {
                  // setShowAdvancedSettings((prevValue) => !prevValue);
                }}
              >
                Restore All Settings to Default
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

export default Settings;
