import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { columns } from "./data";

export default function SubscriptionsTable({ user, setUser }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const TableRows = user.subscriptions
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((subscription) => {
      // We color the status green if its active
      const subscriptionStatusColor =
        subscription.Status == "Active" ? "blue" : "black";

      // Subscriptions
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={subscription.code}>
          {columns.map((column) => {
            if (column.label == "MPESA Code") return;
            return (
              <TableCell
                key={column.id}
                align={column.align}
                sx={{
                  color:
                    column.label == "Status" ? subscriptionStatusColor : "",
                }}
              >
                {column.format && typeof subscription[column.label] === "number"
                  ? `${column.format(subscription[column.label])}`
                  : column.type == "Date"
                  ? `${new Date(subscription[column.label].seconds * 1000)}`
                  : `${subscription[column.label]}`}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                if (column.label == "MPESA Code") return;

                return (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{ minWidth: column.type == "Date" ? "280px" : "" }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>{TableRows}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={user?.subscriptions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
