import React from "react";
import Links from "./Links";
import MenuIconComp from "./MenuIconComp";

function Menu({
  setShowExpandedMenu,
  showExpandedMenu,
  user,
  setUser,
  activeNavLink,
  setActiveNavLink,
}) {
  return (
    <div className="my-auto">
      <Links
        user={user}
        setUser={setUser}
        activeNavLink={activeNavLink}
        setActiveNavLink={setActiveNavLink}
      />
      <MenuIconComp
        activeNavLink={activeNavLink}
        setShowExpandedMenu={setShowExpandedMenu}
        showExpandedMenu={showExpandedMenu}
      />
    </div>
  );
}

export default Menu;
