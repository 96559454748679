import React from "react";

function Audience() {
  return (
    <div>
      <div className="text-2xl font-bold my-2">Target Audience</div>

      <div className="space-y-2">
        <div>
          Welcome to Verbot, where we cater to the needs of transcribers on the
          Verbit platform, specifically those with editor or reviewer accounts.
          Whether you're a seasoned transcriber or just starting out, Verbot is
          designed with you in mind. Our platform is tailored to meet the unique
          needs of Kenyan transcribers, providing innovative solutions to
          streamline your workflow and maximize your efficiency.
        </div>

        <div>
          As a transcriber on the Verbit platform, you understand the importance
          of staying ahead of the curve in the competitive world of
          transcription. Verbot offers you the tools and resources you need to
          excel in your role, whether you're editing transcripts for accuracy or
          providing valuable feedback as a reviewer. With our advanced web
          scraping technology, you can access job orders on Verbit as soon as
          they're available, giving you a competitive edge and ensuring that you
          never miss out on valuable opportunities.
        </div>

        <div>
          But Verbot goes beyond just job order management on Verbit. We
          understand that as a Kenyan transcriber, you may also be interested in
          exploring opportunities on other Verbit-related websites. That's why
          we're continuously expanding our capabilities to include web scraping
          for other Verbit-related platforms, allowing you to access even more
          job opportunities and maximize your earning potential.
        </div>

        <div>
          At Verbot, our commitment to our Kenyan transcribers is unwavering.
          We're dedicated to providing you with the support and resources you
          need to succeed in your transcription career. Whether you're looking
          to increase your efficiency, boost your earnings, or simply save time,
          Verbot is here to help you achieve your goals.
        </div>

        <div>
          Join us at Verbot and experience the difference in transcription
          excellence. Sign up today and unlock your full potential as a Kenyan
          transcriber on the Verbit platform.
        </div>
      </div>
    </div>
  );
}

export default Audience;
