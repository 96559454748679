import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import MessageIcon from "@mui/icons-material/Message";

export const footerItems = [
  // Contacts data
  {
    name: "Contacts",
    items: [
      {
        itemName: "Message Me",
        itemIcon: (
          <div className="text-sm flex space-x-1 mt-0 text-green-400 cursor-pointer">
            <MessageIcon fontSize="medium" />
            <div className="my-auto">Message</div>
          </div>
        ),
      },
      {
        itemName: "Twitter",
        itemLink: "https://twitter.com/devmwas",
        linkType: "external",
        itemIcon: <TwitterIcon color="primary" fontSize="medium" />,
      },
      // {
      //   itemName: "LinkedIn",
      //   itemLink: "https://www.linkedin.com/in/mwangi-kinuthia/",
      //   linkType: "external",
      //   itemIcon: <LinkedInIcon color="primary" fontSize="medium" />,
      // },
      {
        itemName: "GitHub",
        itemLink: "https://github.com/devmwas",
        linkType: "external",
        itemIcon: <GitHubIcon color="primary" fontSize="medium" />,
      },
    ],
  },

  // About Us
  {
    name: "About Us",
    items: [
      {
        itemName: "About",
        itemLink: "/about",
        linkType: "internal",
      },
      {
        itemName: "Justification",
        itemLink: "/about/why_verbot",
        linkType: "internal",
      },
      // {
      //   itemName: "Developer",
      //   itemLink: "/about/developer",
      //   linkType: "internal",
      // },
      {
        itemName: "Audience",
        itemLink: "/about/target_audience",
        linkType: "internal",
      },
    ],
  },

  // Page Links
  {
    name: "Page Links",
    items: [
      {
        itemName: "Home",
        itemLink: "/",
        linkType: "internal",
      },
      {
        itemName: "Settings",
        itemLink: "/settings",
        linkType: "internal",
      },
      // {
      //   itemName: "Subscriptions",
      //   itemLink: "/subscriptions",
      //   linkType: "internal",
      // },
      {
        itemName: "Account",
        itemLink: "/account",
        linkType: "internal",
      },
    ],
  },

  // Page Sections
  {
    name: "Page Sections",
    items: [
      {
        itemName: "Subscribe",
        itemLink: "/subscriptions#subscribe",
        linkType: "internal",
      },
      {
        itemName: "Subscriptions",
        itemLink: "/subscriptions#all_subscriptions",
        linkType: "internal",
      },
      // {
      //   itemName: "Basic Settings",
      //   itemLink: "/settings#basic",
      //   linkType: "internal",
      // },
      {
        itemName: "Advanced",
        itemLink: "/settings#advanced",
        linkType: "internal",
      },
    ],
  },

  // Frequently Asked Questions
  {
    name: "Frequently Asked Questions - FAQs",
    items: [
      {
        id: "how_to_use",
        itemName: "Usage",
        itemLink: "/faqs/how_to_use",
        linkType: "internal",
      },
      // {
      //   id: "restore_settings",
      //   itemName: "Restore Settings",
      //   itemLink: "/faqs/restore_settings",
      //   linkType: "internal",
      // },
      {
        id: "deposit_money",
        itemName: "Deposit Money",
        itemLink: "/faqs/deposit_money",
        linkType: "internal",
      },
      {
        id: "how_to_subscribe",
        itemName: "Subscribe",
        itemLink: "/faqs/how_to_subscribe",
        linkType: "internal",
      },
    ],
  },

  // Logo - Story for another day
];
