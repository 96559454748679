import React from "react";

function SubscriptionDetail({ subscriptionDetail }) {
  const { name, value } = subscriptionDetail;

  return (
    <div className="flex space-x-2">
      {/* Our Subscription Detail */}
      <div className="w-1/2 md:text-right">{name}:</div>
      {/* Our Subscription Value */}
      <div className="w-1/2 font-bold">
        {typeof value == "number" ? value.toLocaleString("en-US") : value}
      </div>
    </div>
  );
}

export default SubscriptionDetail;
