import Logo from "./Logo";
import Menu from "./Menu";

function Navbar({
  setShowExpandedMenu,
  showNavbar,
  user,
  setUser,
  activeNavLink,
  setActiveNavLink,
}) {
  if (showNavbar)
    return (
      <div
        className="flex p-1 md:p-2 w-full xl:w-[75%] xl:ml-[12.5%] justify-between opacity-90 bg-black z-50"
        style={{
          position: "fixed",
          top: "0px",
        }}
      >
        <Logo
          activeNavLink={activeNavLink}
          setActiveNavLink={setActiveNavLink}
        />
        <Menu
          setShowExpandedMenu={setShowExpandedMenu}
          user={user}
          setUser={setUser}
          activeNavLink={activeNavLink}
          setActiveNavLink={setActiveNavLink}
        />
      </div>
    );
}

export default Navbar;
