import { Button } from "@mui/material";
import React, { useState } from "react";

function StartStop() {
  const [running, setRunning] = useState(false);

  return (
    <Button
      fullWidth
      fontSize="small"
      variant="contained"
      color={running ? "warning" : "success"}
      onClick={() => setRunning((prevValue) => !prevValue)}
    >
      {running ? "STOP" : "START"}
    </Button>
  );
}

export default StartStop;
