import React from "react";
import SubscriptionsTable from "./SubscriptionsTable";
import { Card } from "@mui/material";

function PastSubscriptions({ user, setUser }) {
  return (
    <div className="py-8 px-1 md:px-0 mx-auto" id="all_subscriptions">
      <Card sx={{ padding: "8px" }}>
        <div className="font-bold pb-4">Subscriptions</div>
        {/* We only render the table when we have past subscriptions */}
        <Card elevation={4}>
          {user?.subscriptions.length > 0 && (
            <SubscriptionsTable user={user} setUser={setUser} />
          )}
        </Card>
        {user?.subscriptions.length == 0 && (
          <div>
            Dear Customer, you don't have a subscription history. Please buy a
            subscription and enjoy our services!
          </div>
        )}
      </Card>
    </div>
  );
}

export default PastSubscriptions;
