export const ourSessionDetails = [
  {
    name: "Start Time",
    value: "08:58 AM",
  },
  {
    name: "Scrap Duration",
    value: "3 hrs 45 mins",
  },
  {
    name: "Session Number",
    value: 1,
  },
  {
    name: "Refresh Number",
    value: 1000,
  },
  {
    name: "Is a Job Opened?",
    value: true,
  },
];

export const openedJobDetails = [
  {
    leftDetail: "Length",
    leftValue: "3 h 24 mins",
    rightDetail: "Pay/min",
    rightValue: "25c/min",
  },
  {
    leftDetail: "Total Pay",
    leftValue: "$ 3.5",
    rightDetail: "Duration",
    rightValue: "2h 56 mins",
  },
  {
    leftDetail: "Type",
    leftValue: "Clean Read",
    rightDetail: "Link",
    rightValue: <a href="#">Link</a>,
  },
];
