export const sections = [
  {
    imgSrc: "https://picsum.photos/1000/600",
    text:
      "Get a list of images by using the /v2/list endpoint." +
      "The API will return 30 items per page by default. " +
      "To request another page, use the ?page parameter. " +
      "To change the amount of items per page, use the ?limit parameter." +
      "The Link header includes pagination information about the next/previous pages." +
      "Get a list of images by using the /v2/list endpoint." +
      "The API will return 30 items per page by default. " +
      "To request another page, use the ?page parameter. " +
      "To change the amount of items per page, use the ?limit parameter." +
      "The Link header includes pagination information about the next/previous pages.",
  },
  {
    imgSrc: "https://picsum.photos/1000/600",
    text:
      "Get a list of images by using the /v2/list endpoint." +
      "The API will return 30 items per page by default. " +
      "To request another page, use the ?page parameter. " +
      "To change the amount of items per page, use the ?limit parameter." +
      "The Link header includes pagination information about the next/previous pages." +
      "Get a list of images by using the /v2/list endpoint." +
      "The API will return 30 items per page by default. " +
      "To request another page, use the ?page parameter. " +
      "To change the amount of items per page, use the ?limit parameter." +
      "The Link header includes pagination information about the next/previous pages.",
  },
  {
    imgSrc: "https://picsum.photos/1000/600",
    text:
      "Get a list of images by using the /v2/list endpoint." +
      "The API will return 30 items per page by default. " +
      "To request another page, use the ?page parameter. " +
      "To change the amount of items per page, use the ?limit parameter." +
      "The Link header includes pagination information about the next/previous pages." +
      "Get a list of images by using the /v2/list endpoint." +
      "The API will return 30 items per page by default. " +
      "To request another page, use the ?page parameter. " +
      "To change the amount of items per page, use the ?limit parameter." +
      "The Link header includes pagination information about the next/previous pages.",
  },
  {
    imgSrc: "https://picsum.photos/1000/600",
    text:
      "Get a list of images by using the /v2/list endpoint." +
      "The API will return 30 items per page by default. " +
      "To request another page, use the ?page parameter. " +
      "To change the amount of items per page, use the ?limit parameter." +
      "The Link header includes pagination information about the next/previous pages." +
      "Get a list of images by using the /v2/list endpoint." +
      "The API will return 30 items per page by default. " +
      "To request another page, use the ?page parameter. " +
      "To change the amount of items per page, use the ?limit parameter." +
      "The Link header includes pagination information about the next/previous pages.",
  },
];
