import React from "react";

function Index() {
  return (
    <div>
      <div className="text-2xl font-bold my-2">About Us</div>
      <div className="space-y-2">
        <div>
          Welcome to Verbot, your ultimate companion in the dynamic world of job
          orders on Verbit. Verbot is revolutionizing the way transcribers
          approach their work, providing instantaneous access to job orders on
          Verbit and seamless subscription management through M-PESA, Kenya's
          innovative mobile money technology.
        </div>

        <div>
          At Verbot, our mission is simple: to empower transcribers with the
          tools they need to thrive in a competitive landscape. With our
          advanced web scraping technology, powered by an intelligent bot,
          Verbot automatically opens new job orders on Verbit the moment they
          appear. This ensures that you're always ahead of the curve, ready to
          seize every opportunity that comes your way.
        </div>

        <div>
          But Verbot goes beyond just job order management. We understand that
          seamless subscription management is essential for your success. That's
          why we've partnered with M-PESA, enabling users to purchase
          subscriptions with ease. Whether you prefer a daily, weekly,
          bi-weekly, or custom subscription plan, Verbot has you covered. With
          just a few taps on your mobile device, you can unlock premium features
          and take your transcription career to the next level.
        </div>

        <div>
          At Verbot, we support both editors and reviewers on the Verbit
          platform. Whether you're polishing transcripts for accuracy or
          providing valuable feedback as a reviewer, Verbot ensures that you
          have the tools and resources you need to excel in your role. With our
          user-friendly interface and real-time job notifications, you can focus
          on delivering high-quality work without the hassle of manual job
          searching.
        </div>

        <div>
          Join us at Verbot and experience a new standard of efficiency and
          convenience in the world of transcription. Say goodbye to tedious
          manual tasks and hello to automated success with Verbot – your trusted
          partner in transcription excellence.
        </div>
      </div>
    </div>
  );
}

export default Index;
