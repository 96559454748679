import { useEffect, useState } from "react";
import { links } from "./data";
import { Link } from "react-router-dom";

function Links({ activeNavLink, setActiveNavLink, user }) {
  const [accountLinkName, setAccountLinkName] = useState("Account");

  // Updating the Account Page Link Navbar link name
  useEffect(() => {
    if (user !== null) {
      if (user.displayName !== null) {
        // const userEmail = user.email.split("@")[0].slice(0, 5) + "...";
        // setAccountLinkName(userEmail);
        const firstName = user.displayName.split(" ")[0];
        const firstChar = firstName.charAt(0).toUpperCase();
        const username = firstChar + firstName.slice(1);
        setAccountLinkName(username);
      } else {
        setAccountLinkName("Account");
      }
    } else {
      setAccountLinkName("Account");
    }
  }, [user]);

  // This will contain the Link Name and the Link Icon
  const linkComponents = links.map((link, index) => {
    const { linkName, LinkIcon } = link;
    // We don't want to show the Home page link on large devices since it's already taken
    // care of by the Logo, which doesn't exist on Expanded Menu, which shows on small devices
    if (linkName === "Verbot") return;
    return (
      <div
        key={index}
        className={`cursor-pointer ${
          activeNavLink === linkName ? "text-orange-400 font-bold" : ""
        }`}
        style={{
          fontSize: "16px",
          lineHeight: "27px",
          letterSpacing: "3px",
        }}
      >
        <a href={`#${linkName}`} onClick={() => setActiveNavLink(linkName)}>
          <Link to={`/${linkName.toLocaleLowerCase()}`}>
            <div className="flex space-x-2">
              <div>{LinkIcon}</div>
              <div>{linkName == "Account" ? accountLinkName : linkName}</div>
            </div>
          </Link>
        </a>
      </div>
    );
  });

  return (
    <div>
      <div>
        {/* This text navbar will not show on mobile phones */}
        <div className="hidden md:flex my-auto space-x-6 text-sky-400">
          {/* Our Navbar Links */}
          {linkComponents}
        </div>
      </div>
    </div>
  );
}

export default Links;
