import React from "react";

function Why() {
  return (
    <div>
      <div className="text-2xl font-bold my-2">Why Choose Us</div>

      <div className="space-y-2">
        <div>
          At Verbot, we're dedicated to revolutionizing the way transcribers
          approach their work. Here's why choosing us is your best decision:
        </div>
        {/* Reasons to Choose Us */}
        <div>
          1. Maximize Job Opportunities: Verbot significantly increases the
          number of job orders opened for you on Verbit. Our advanced web
          scraping bot ensures that you never miss out on new opportunities,
          giving you a competitive edge in securing more jobs.
        </div>
        <div>
          2. Boost Earnings: Your income as a transcriber depends on the number
          of jobs completed. With Verbot, you can significantly increase your
          earning potential by accessing more job orders and completing them
          efficiently.
        </div>
        <div>
          3. Save Time: We understand that time is precious. Verbot eliminates
          the tedious task of manually searching for job orders by automatically
          opening them for you. This saves you valuable time and allows you to
          focus on what truly matters – completing tasks.
        </div>
        <div>
          4. Reduce Workload: Transcribers often face a heavy workload. Verbot
          lightens this burden by handling the repetitive task of job order
          monitoring, allowing you to manage your workload more effectively.
        </div>
        <div>
          5. Flexibility: With Verbot, you have complete control over bot
          settings. Easily tweak parameters such as the frequency of page
          refreshes on the Verbit website to match your preferences and
          workload.
        </div>
        <div>
          6. Instant Subscription Purchase: Buying a subscription with Verbot is
          quick and effortless. Using Safaricom's M-PESA, you can complete the
          transaction in a matter of seconds, ensuring uninterrupted access to
          premium features.
        </div>
        <div>
          7. Stay Ahead of the Competition: Verbot puts you ahead of the
          competition. With our innovative technology and commitment to
          excellence, you'll always be one step ahead in the fast-paced world of
          transcription.
        </div>
        <div>
          Choose Verbot and experience the difference in efficiency, earnings,
          and convenience. Join us today and unlock your full potential in the
          transcription industry.
        </div>
      </div>
    </div>
  );
}

export default Why;
