import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import ExpandedMenuAnimation from "../animations/ExpandedMenuAnimation";

function Loading({ user, loadingUser, setLoadingUser }) {
  useEffect(() => {
    setLoadingUser(false);
  }, [user]);

  if (!loadingUser) return null;
  return createPortal(
    <div
      className="flex flex-col justify-center"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
        opacity: 1,
        backgroundColor: "black",
      }}
    >
      <div>
        {/* We add framer motion animations for more fanciness */}
        <ExpandedMenuAnimation
          loadingUser={loadingUser}
          setLoadingUser={setLoadingUser}
        >
          {/* Our Loading Info */}
          <div className="text-center text-sky-400 text-4xl">Loading...</div>
        </ExpandedMenuAnimation>
      </div>
    </div>,
    document.querySelector("#expandedMenuPortal")
  );
}

export default Loading;
