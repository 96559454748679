import React, { useEffect } from "react";
import Subscribe from "./Subscribe";
import PastSubscriptions from "./PastSubscriptions";
import { db } from "../../firebase/app";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { Card } from "@mui/material";

function SubscriptionsPage({ user, setUser }) {
  // Fetching user's subscriptions
  useEffect(() => {
    if (user) {
      fetchAllSubscriptions();
    }
  }, []);

  // Function to fetch all subscriptions of a certain user
  const fetchAllSubscriptions = () => {
    console.log("Fetching subs..");
    // Getting a reference for users' collection
    const usersCollection = collection(db, "users");
    // Create a query to search for documents where the userId field matches the provided value
    const q = query(usersCollection, where("userId", "==", user.userId));

    // Fetch all documents in the subscriptions subcollection
    return getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          alert("No subscriptions found");
        } else {
          // Get the first matching document
          const userDoc = querySnapshot.docs[0];
          const userRef = doc(db, "users", userDoc.id); // Reference to the user document
          const subscriptionsRef = collection(userRef, "subscriptions"); // Reference to the subscriptions subcollection

          // We set the fetch subscriptions status
          getDocs(subscriptionsRef)
            .then((querySnapshot) => {
              console.log("Query Snapshot: ", querySnapshot.docs[0].data());
              const subs = querySnapshot.docs.map((doc) => {
                return { ...doc.data() };
              });
              console.log("Subs: ", subs);
              // Update the User global object with the subscriptions data fro db
              setUser((prevUser) => {
                return {
                  ...prevUser,
                  subscriptions: subs,
                };
              });
            })
            .catch((error) => alert("Error: ", error));
        }
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error);
        throw error;
      });
  };

  return (
    <div className="bg-mangenta-400 px-1 md:px-4 mx-auto">
      <Card>
        <Subscribe />
      </Card>
      <PastSubscriptions user={user} setUser={setUser} />
    </div>
  );
}

export default SubscriptionsPage;
