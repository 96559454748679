import React from "react";

function OpenedJobDetail({ openedJobDetail }) {
  const { leftDetail, leftValue, rightDetail, rightValue } = openedJobDetail;
  return (
    <div className="flex justify-between w-full space-x-4">
      <div className="flex justify-between w-1/2">
        <div>{leftDetail}</div>
        <div className="font-bold">{leftValue}</div>
      </div>
      <div className="flex justify-between w-1/2">
        <div>{rightDetail}</div>
        <div className="font-bold">{rightValue}</div>
      </div>
    </div>
  );
}

export default OpenedJobDetail;
