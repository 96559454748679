import { Button } from "@mui/material";
import React from "react";

function Developer() {
  return (
    <div>
      <div className="text-2xl font-bold my-2">About Me</div>
      <div className="space-y-2">
        <div>
          Greetings! I'm Mwangi Morris Kinuthia, also known as Devmwas, a
          passionate frontend web developer dedicated to crafting captivating
          digital experiences. With a background in Software Engineering, I've
          honed my skills to seamlessly blend creativity with cutting-edge
          technologies. My journey began with a solid foundation in computer
          science, which I now leverage to craft sleek, user-centric interfaces
          for various applications.
        </div>

        <div>
          <div className="font-bold">What sets me apart</div>
          What sets me apart is my unwavering commitment to excellence in
          frontend development. I thrive on pushing the boundaries of
          innovation, constantly seeking out new techniques and technologies to
          enhance user experiences. From designing elegant layouts to
          implementing smooth animations, I take pride in every aspect of the
          development process.
        </div>

        <div>
          <div className="font-bold">Technical expertise</div>
          My expertise lies in JavaScript, React, Redux, and other frontend
          technologies, allowing me to create immersive and dynamic user
          interfaces. Whether it's building responsive web applications or
          optimizing performance for mobile devices, I approach each project
          with meticulous attention to detail and a passion for excellence.
        </div>

        <div>
          <div className="font-bold">The art of design</div>
          Beyond coding, I'm deeply invested in the art of design. I believe
          that great design is not just about aesthetics but also about
          usability and functionality. By understanding user behavior and
          incorporating user feedback, I strive to create interfaces that are
          intuitive, engaging, and memorable.
        </div>

        <div>
          <div className="font-bold">Collaborative spirit</div>
          In addition to my technical skills, I bring a collaborative spirit to
          every project. I thrive in team environments, where I can share ideas,
          learn from others, and contribute to collective success. Whether
          working independently or as part of a team, I'm committed to
          delivering high-quality solutions that exceed expectations.
        </div>

        <div className=" mb-4">
          <div className="font-bold">Let's collaborate!</div>
          <div className="mb-4">
            As Devmwas, I invite you to explore my portfolio and experience
            firsthand the passion and dedication I bring to every project. Let's
            collaborate and elevate your online presence to new heights!
          </div>
          <Button variant="contained" size="small">
            Let's Collaborate
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Developer;
