import { Edit } from "@mui/icons-material";
import { Button, Card, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

function Setting({ user, setting, handleSettingUpdate }) {
  const { name, id, type, options, helpText, minValue, maxValue, payload } =
    setting;
  const [isEditable, setIsEditable] = useState(false);
  // Hiding the Edit icon on un-editable settings
  useEffect(() => {
    if (id == "playAudio" || id == "openJobsStrategy") {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  }, []);

  return (
    <Card sx={{ m: "8px" }}>
      <div className="md:flex justify-between p-1 space-x-0 mb-[2px]">
        <div className="w-full p-1 py-2 md:p-0 md:w-1/2">
          {/* Our Setting's name */}
          <div>
            {name}{" "}
            {/* Here we'll show a ToolTip with more information about a setting */}
            <Tooltip title={helpText}>
              <div className="font-bold inline">
                <a href="#info">?</a>
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="flex w-full p-1 py-2 md:p-0 md:w-1/2 justify-between space-x-1">
          {/* We don't wanna display the Edit icon if value is string or boolean */}
          {/* Our setting's value and edit icon where applicable */}
          {isEditable ? (
            <div className="my-auto w-1/2 p-[5px]">
              <div className="flex space-x-2">
                <div className="cursor-pointer">
                  {/* Edit Icon from MUI */}
                  <Edit variant="contained" color="" fontSize="small" />
                </div>
                {/* Our Setting Value - gotten right from state */}
                <div className="font-bold">
                  {user
                    ? user.advancedSettings[setting.id] ||
                      user.basicSettings[setting.id]
                    : setting.default}
                </div>
              </div>
            </div>
          ) : (
            // Our non-editable Setting Value - gotten right from state
            <div className="font-bold w-1/2 px-2">
              {/* Open Jobs Strategy setting */}
              {setting.id === "openJobsStrategy"
                ? (user && user.basicSettings[setting.id]) || setting.default
                : null}

              {/* Play Audio setting */}
              {setting.id === "playAudio"
                ? user
                  ? user.basicSettings[setting.id]
                    ? "Yes"
                    : "No"
                  : setting.default
                  ? "Yes"
                  : "No"
                : null}
            </div>
          )}

          <div className="my-auto flex space-x-1 w-1/2 justify-end">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() =>
                handleSettingUpdate(
                  "decrement",
                  id,
                  payload,
                  minValue,
                  maxValue,
                  options,
                  type
                )
              }
            >
              {/* Displaying the appropriate icon to change our settings */}
              <div className="text-xl">{isEditable ? "-" : "<"}</div>
            </Button>
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={() =>
                handleSettingUpdate(
                  "increment",
                  id,
                  payload,
                  minValue,
                  maxValue,
                  options,
                  type
                )
              }
            >
              {/* Displaying the appropriate icon to change our settings */}
              <div className="text-xl">{isEditable ? "+" : ">"}</div>
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default Setting;
