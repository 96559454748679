import React from "react";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import { Link } from "react-router-dom";

function Logo({ activeNavLink, setActiveNavLink }) {
  return (
    <div
      className="flex text-blue-400 cursor-pointer"
      onClick={() => setActiveNavLink("Home")}
    >
      <Link to={"/"}>
        <div
          className={`flex space-x-2 ${
            activeNavLink == "Home" ? "text-orange-400" : ""
          }`}
        >
          <div className="mt-0">
            <SmartToyOutlinedIcon fontSize="medium" />
          </div>
          <div className="font-bold">Verbot</div>
        </div>
      </Link>
    </div>
  );
}

export default Logo;
