import React from "react";
import { subscriptionsDetails } from "./data";
import SubscriptionDetail from "./SubscriptionDetail";
import { Button } from "@mui/material";
import axios from "axios";

function SubscriptionDetails({ selectedSubscription }) {
  const SubscriptionDetailsComps = subscriptionsDetails[
    selectedSubscription
  ].map((subscriptionDetail, index) => {
    // Our Subscription details components
    return (
      <SubscriptionDetail key={index} subscriptionDetail={subscriptionDetail} />
    );
  });

  console.log(
    "Subscription Cost: ",
    subscriptionsDetails[selectedSubscription][0].value
  );

  // Handling buy subscription
  const handleBuySubscription = async () => {
    console.log(
      "STK Push Endpoint",
      process.env.REACT_APP_MPESA_PAYMENT_ENDPOINT
    );
    // We'll send the phone number as well as the cost of the subscription to the backend for processing
    const stk_response = await axios.post(
      process.env.REACT_APP_MPESA_PAYMENT_ENDPOINT,
      {
        phoneNumber: "0711341615",
        amount: subscriptionsDetails[selectedSubscription][0].value.toString(),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  return (
    <div className="w-1/2 md:w-full my-auto pt-4">
      <div className="mx-auto text-left">{SubscriptionDetailsComps}</div>
      {/* Buy Subscription Button */}
      <div className="mt-2 mb-0 text-left md:text-center max-w-[200px] md:mx-auto">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleBuySubscription}
        >
          BUY
        </Button>
      </div>
    </div>
  );
}

export default SubscriptionDetails;
