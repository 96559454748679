export const subscriptionsDetails = {
  daily: [
    {
      name: "Cost",
      value: 100,
    },

    {
      name: "Duration",
      value: "1 Day",
    },
    {
      name: "Expiry Date",
      value: "05/2/2024",
    },
  ],
  weekly: [
    {
      name: "Cost",
      value: 750,
    },

    {
      name: "Duration",
      value: "1 Weeks",
    },
    {
      name: "Expiry Date",
      value: "11/1/2024",
    },
  ],
  bi_weekly: [
    {
      name: "Cost",
      value: 1000,
    },

    {
      name: "Duration",
      value: "2 Weeks",
    },
    {
      name: "Expiry Date",
      value: "18/2/2024",
    },
  ],
  monthly: [
    {
      name: "Cost",
      value: 1800,
    },

    {
      name: "Duration",
      value: "1 Month",
    },
    {
      name: "Expiry Date",
      value: "16/3/2024",
    },
  ],
  custom: [
    {
      name: "Cost",
      value: 100,
    },

    {
      name: "Duration",
      value: "1 Day",
    },
    {
      name: "Expiry Date",
      value: "05/2/2024",
    },
  ],
};

export const columns = [
  { id: "date", label: "Date", type: "Date" },
  { id: "expiryDate", label: "Expiry Date", type: "Date" },
  {
    id: "duration",
    label: "Duration",
    // minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "cost",
    label: "Cost",
    // minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "mpesa",
    label: "MPESA Code",
    // minWidth: 170,
    align: "right",
  },
  {
    id: "status",
    label: "Status",
    // minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];
