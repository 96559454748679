import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";

export const links = [
  // {
  //   linkName: "Stats",
  //   LinkIcon: <BarChartIcon />,
  // },
  {
    linkName: "Verbot",
    linkURL: "/",
    LinkIcon: <SmartToyOutlinedIcon fontSize="small" />,
  },
  {
    linkName: "Notifications",
    linkURL: "/notifications",
    LinkIcon: <NotificationsIcon fontSize="small" />,
  },
  {
    linkName: "Settings",
    linkURL: "/settings",
    LinkIcon: <SettingsIcon fontSize="small" />,
  },
  {
    linkName: "Subscriptions",
    linkURL: "/subscriptions",
    LinkIcon: <SubscriptionsIcon fontSize="small" />,
  },
  // {
  //   linkName: "Downloads",
  //   LinkIcon: <DownloadIcon />,
  // },
  {
    linkName: "Account",
    linkURL: "/account",
    LinkIcon: <AccountBoxIcon fontSize="small" />,
  },
];
