import React from "react";

function Section({ imgSrc, text }) {
  return (
    <div>
      {/* Section Image */}
      {imgSrc && (
        <div>
          <img href={imgSrc} />
        </div>
      )}
      <div>
        <img href={imgSrc} />
      </div>
      <img src={imgSrc} />
      {/* Section Text */}
      <div>{text}</div>
    </div>
  );
}

export default Section;
