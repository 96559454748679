import React from "react";
import { sections } from "./data";
import Section from "./Section";
import PreviewNotification from "../preview/PreviewNotification";

function DetailedNotification({ showPreviews, setShowPreviews }) {
  // Sections.paragraphs of the full story
  const Sections = sections.map((sectionData, index) => {
    const { imgSrc, text } = sectionData;

    return <Section key={index} imgSrc={imgSrc} text={text} />;
  });

  if (showPreviews) return;

  return (
    <div className="mx-2 space-y-2">
      <div className="font-bold">Full Story</div>

      {/* Notification Section */}
      {Sections}

      {/* Preview Notifications */}
      <div className="pt-4">
        <div className="text-blue-500 underline">Preview Notifications</div>
        <div className="space-y-4">
          <PreviewNotification
            showPreviews={showPreviews}
            setShowPreviews={setShowPreviews}
          />
          <PreviewNotification
            showPreviews={showPreviews}
            setShowPreviews={setShowPreviews}
          />
          <PreviewNotification
            showPreviews={showPreviews}
            setShowPreviews={setShowPreviews}
          />
          <PreviewNotification
            showPreviews={showPreviews}
            setShowPreviews={setShowPreviews}
          />
          <PreviewNotification
            showPreviews={showPreviews}
            setShowPreviews={setShowPreviews}
          />
        </div>
      </div>
    </div>
  );
}

export default DetailedNotification;
