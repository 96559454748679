import React from "react";

function HowToUse() {
  return (
    <div>
      <div>How To Use Verbot</div>
      <div>
        Using Verbot is easy. First of all, you'll need a Verbit account.
        Secondly, you'll need to be Kenyan because right now we only support
        Kenyans. We're woorking on including other nations in the future.
      </div>
      <div>
        Then you'll need to sign up with your Google account. It is important to
        know that you don't have to sign up with your Verbit email address. It
        is even not reccommended to do so. We reccommend you sign up with your
        day-to-day email address and set up your Verbit email address after
        signing up.
      </div>
      <div>
        After signing up with your Google account and setting up your account
        details, you will then need to buy a subscription before you start using
        our bot to find and open jobs.
      </div>
      <div>
        You can the proceed to configure your settings to customize the bot
        behavior for your needs.
      </div>
    </div>
  );
}

export default HowToUse;
