import React, { useEffect, useState } from "react";
import { Button, Input, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/app";

function SetAccountDetails({ user, setUser, setActiveNavLink }) {
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();
  const [updatingStatus, setUpdatingStatus] = useState("");

  // We dont want all textfields to start on error state as it's unaesthetic, so we use multiple useEffects
  // We validate the email after every change is made to it
  useEffect(() => {
    // We ensure that we're not setting error on the first render when our state will be undefined
    if (!(user.verbitEmail === undefined)) {
      // We trim the email before any validation starts
      !validateEmail(user.verbitEmail.trim())
        ? setEmailError(true)
        : setEmailError(false);
    }
  }, [user.verbitEmail]);

  // We validate the phone number after every change is made to it
  useEffect(() => {
    // We ensure that we're not setting error on the first render when our state will be undefined
    if (!(user.phone === undefined)) {
      // We trim the email before any validation starts
      !validatePhone(user.phone.trim())
        ? setPhoneError(true)
        : setPhoneError(false);
    }
  }, [user.phone]);

  // Updating the state after every change
  const handleChange = (e) => {
    if (e.target.name == "accountType") {
      // We update the local user state
      setUser((prevData) => {
        return {
          ...prevData,
          accountType: user.accountType == "Reviewer" ? "Editor" : "Reviewer",
        };
      });
    } else {
      setUser((prevData) => {
        return {
          ...prevData,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  // This function will validate emails and return a Boolean result
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhone = (phone) => {
    return String(phone).match(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    );
  };

  // This function will check if we have any errors and return a Boolean indicating the presence of errors
  const checkErrors = () => {
    if (!emailError) {
      if (!phoneError) {
        return false;
      } else return true;
    } else return true;
  };

  // This function will check if we have any undefined state variables and help in data validation
  const checkUndefined = () => {
    if (!(user.verbitEmail === undefined)) {
      if (!(user.phone === undefined)) {
        return false;
      } else return true;
    } else return true;
  };

  // We check for both undefineds and errors
  const checkErrorsOrUndefined = () => {
    // If we have errors or undefineds
    if (checkErrors() || checkUndefined()) return true;
    // If we have no errors or undefineds
    return false;
  };

  // Function to update the user document by adding or editing phone and verbitEmail fields
  const updateUserDocument = () => {
    // Set updating status to updating
    setUpdatingStatus("updating");
    const usersCollection = collection(db, "users");

    // Create a query to search for documents where the userId field matches the provided value
    const q = query(usersCollection, where("userId", "==", user.userId));

    // Execute the query and get the result
    return getDocs(q)
      .then((querySnapshot) => {
        // Check if any documents match the query
        if (!querySnapshot.empty) {
          // Get the first matching document
          const userDoc = querySnapshot.docs[0];
          const userRef = doc(db, "users", userDoc.id); // Reference to the user document

          // If we have no errors, we send the message
          // This check is not so useful and I will remove it in future. We cannot reach this function if we have any errors or undefineds because the Send Message button will be disabled in either case
          if (!checkErrors()) {
            // We trim all user inputs off any leading or trailing whitespaces
            const trimmedUser = {
              verbitEmail: user.verbitEmail.trim(),
              phone: user.phone.trim(),
              accountType: user.accountType.trim(),
            };

            // Update the user document with trimmed phone and verbitEmail fields
            return updateDoc(userRef, trimmedUser)
              .then(() => {
                console.log("Trimmed user data: ", trimmedUser);
                // We then update the global state to reflect this new state
                // We do it cleverly to avoid unnecessarily hitting the database for locally available data
                setUser((prevUser) => {
                  return {
                    ...prevUser,
                    ...trimmedUser,
                  };
                });
                // Persist user in local storage
                localStorage.setItem(
                  "user",
                  JSON.stringify({
                    ...user,
                    ...trimmedUser,
                  })
                );
                // Set updating status to updated
                setUpdatingStatus("updated");
                console.log(
                  "Your account details have been updated successfully!"
                );
                alert("You account details have been updated successfully!");
                // We set the active link to the redirected home link
                setActiveNavLink("Account");
                // Navigate to the Home Page
                navigate("/user_details");
              })
              .catch((error) => {
                console.error("Error updating user document:", error);
                console.log("Error updating user document!");
                alert("An Error occured while updating your data!");
                // We set the active link to the redirected home link
                setActiveNavLink("Account");
                // Navigate to the Home Page
                navigate("/edit");
              });
          }
        } else {
          // No matching document found
          console.log(
            "User document with userId: ",
            user.userId,
            "does not exist"
          );
        }
      })
      .catch((error) => {
        console.error("Error searching for user document:", error);
      });
  };

  return (
    <div
      className={`bg-white mt-[50px] opacity-100 space-y-4 p-2 sm:p-4 m-2 sm:mx-auto`}
      style={{
        maxWidth: "500px",
        borderRadius: "2%",
        backgroundImage: "linear-gradient(to bottom right, white, #eee, white)",
      }}
    >
      <div className="flex justify-between">
        <div className="text-blue-400 font-bold">Edit Your Account Details</div>
      </div>

      {/* Verbit Email address */}
      <div>
        {emailError ? (
          <TextField
            label="Verbit E-mail address"
            fullWidth
            size="small"
            name="verbitEmail"
            error
            helperText="Please provide a valid email."
            value={user.verbitEmail}
            onChange={handleChange}
          />
        ) : (
          <TextField
            label="Verbit E-mail address"
            fullWidth
            size="small"
            name="verbitEmail"
            value={user.verbitEmail}
            onChange={handleChange}
          />
        )}
      </div>

      {/* User Phone number  */}
      <div>
        <div className={`${phoneError ? "text-red-600" : ""}`}>
          M-PESA Phone Number
        </div>
        {phoneError ? (
          <Input
            label="Safaricom phone number"
            fullWidth
            type="phone"
            size="small"
            name="phone"
            error
            helperText="Please provide a valid phone number."
            value={user.phone}
            onChange={handleChange}
          />
        ) : (
          <Input
            label="Safaricom phone number"
            fullWidth
            type="phone"
            size="small"
            name="phone"
            value={user.phone}
            onChange={handleChange}
          />
        )}
      </div>

      {/* User Account Type: Editor/Reviewer  */}
      <div>
        <div className={`${phoneError ? "text-red-600" : ""}`}>
          Account Type (Editor/Reviewers)
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={user.accountType == "Reviewer" ? true : false}
                onChange={handleChange}
                name="accountType"
              />
            }
            label={`${
              user.accountType == "Reviewer" ? "Reviewer?" : "Editor?"
            }`}
          />
        </div>
      </div>

      <div className="text-center">
        {checkErrorsOrUndefined() ? (
          // We disable Sending button if we have any errors or any undefined variables
          <Button variant="contained" disabled>
            {updatingStatus !== "updating" ? "UPDATE" : "UPDATING"}
          </Button>
        ) : (
          //  We only enable he send button if we don't have any undefineds or errors
          <Button variant="contained" onClick={updateUserDocument}>
            {updatingStatus !== "updating" ? "UPDATE" : "UPDATING"}
          </Button>
        )}
      </div>
    </div>
  );
}

export default SetAccountDetails;
