// Our Settings data
export const basicSettings = [
  {
    id: "openJobsStrategy",
    type: "basic",
    name: "Open Jobs Strategy",
    options: ["No New Tabs", "Open Multiple Tabs"],
    default: "No New Tabs",
    helpText:
      "We have two options for this setting: no new tabs and open multiple tabs." +
      "If no new tabs setting is chosen and the bot finds multiple jobs at the same time," +
      "it will open all the jobs, one at a time, all in the same tab. " +
      "This is reccommended since the browser might have performance issues on opening too many tabs. " +
      "However, if we select open multiple tabs, all the new jobs will each be opened in its own new tab. " +
      "This might lead to performance issues, especially on Google Chrome.",
  },
  {
    id: "playAudio",
    type: "basic",
    name: "Play Audio to Notify User of Open Jobs",
    options: [false, true],
    default: true,
    helpText:
      "If True, we will play an audio after finding an already opened job. " +
      "This is useful in alerting the user especially in cases where the user is not near his/her " +
      "laptop or where the user is busy doing other things on the same laptop.",
  },
  {
    id: "scrapFirstPagePercentage",
    type: "basic",
    name: "Scrap The First Page How Oftenly (%)",
    minValue: 0,
    maxValue: 100,
    default: 100,
    payload: 5,
    settingUnit: "%",
    helpText:
      "How often do you want to scrap the first page in percentage? This setting can help " +
      "users avoid scraping the second page by setting it to 100%. Scraping the second page sometimes" +
      "causes errors as well leads to unnecessary time wastage since the bot has to scroll down " +
      "manually to find the next page button.",
  },
  {
    id: "refreshAfterMin",
    type: "basic",
    name: "Refresh After How Many Seconds (MIN)",
    minValue: 2,
    maxValue: 100,
    default: 5,
    payload: 1,
    settingUnit: "seconds",
    helpText:
      "One thing about using a bot to scrap is that it can be detected by the platform you're " +
      "scraping because of being too predictable. For example, if you refresh the page after every 2 " +
      "seconds, it's clearly evident that you're using an automated tool. To prevent this, we have this " +
      "setting to allow you to specify the minimum number of seconds to refresh the page after. It " +
      "cannot refresh the page if this time has not passed. We use random values between your minimum " +
      "refresh time and maximum refresh time to mimick a real human user.",
  },
  {
    id: "refreshAfterMax",
    type: "basic",
    name: "Refresh After How Many Seconds (MAX)",
    minValue: 3,
    maxValue: 100,
    default: 8,
    payload: 1,
    settingUnit: "seconds",
    helpText:
      "One thing about using a bot to scrap is that it can be detected by the platform you're " +
      "scraping because of being too predictable. For example, if you refresh the page after every 2 " +
      "seconds, it's clearly evident that you're using an automated tool. To prevent this, we have this " +
      "setting to allow you to specify the miximum number of seconds to refresh the page after. " +
      "We use random values between your minimum refresh time and maximum refresh time to mimick " +
      "a real human user.",
  },
];

export const advancedSettings = [
  {
    id: "scrapSecondPagePercentage",
    type: "advanced",
    name: "If Second Page Exists, Scrap It How Oftenly (%)",
    minValue: 5,
    maxValue: 100,
    default: 20,
    payload: 5,
    settingUnit: "%",
    helpText:
      "Some users might still want to scrap the second page. This setting helps you specify " +
      "how often you want the bot to scrap the second page. If you always want to scrap the second " +
      "page, set this to 100%.",
  },
  {
    id: "minNoOfJobs",
    type: "advanced",
    name: "Minimum number of jobs to keep in file",
    minValue: 200,
    maxValue: 1000,
    default: 500,
    payload: 50,
    settingUnit: "jobs",
    helpText:
      "The bot keeps a file containing jobs scraped. This helps it to identify new jobs and thus " +
      "avoid unnecessarily opening jobs that have been in the platform for ages. However, if this file " +
      "becomes too small, then the bot might fail to differentiate the old jobs from the new ones.",
  },
  {
    id: "maxNoOfJobs",
    type: "advanced",
    name: "Maximum number of jobs to keep in file",
    minValue: 1000,
    maxValue: 10000,
    default: 1000,
    payload: 50,
    settingUnit: "jobs",
    helpText:
      "The bot keeps a file containing jobs scraped. This helps it to identify new jobs and thus " +
      "avoid unnecessarily opening jobs that have been in the platform for ages. However, if this file " +
      "grows too large, it causes slow performance.",
  },
  {
    id: "startOpeningJobs",
    type: "advanced",
    name: "Start opening jobs after how many refreshes",
    minValue: 1,
    maxValue: 100,
    payload: 1,
    default: 5,
    settingUnit: "refreshes",
    helpText:
      "Once you start running the bot, any job it finds is considered a new job and thus the " +
      "bot proceeds to open it. This is unnecessary since the jobs might be those jobs that are " +
      "always there in the platform, which no one wants to do. This setting allows you to prevent " +
      "this behavior. All jobs scrapped in the first few refreshes, as specified by user via this setting " +
      "are stored in our jobs file, but none is opened, whether it is legitimately new or not. " +
      "The downside is that you might miss a chance to open a job in the first few refreshes.",
  },
];
