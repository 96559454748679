import HowToUse from "./HowToUse";
import RestoreSettings from "./RestoreSettings";
import DepositMoney from "./DepositMoney";
import BuySubscription from "./BuySubscription";

export const faqsData = [
  {
    faqTitle: "How to use Verbot for a beginner?",
    component: <HowToUse />,
  },
  {
    faqTitle: "How to Restore Settings?",
    component: <RestoreSettings />,
  },
  {
    faqTitle: "How to use Deposit Money?",
    component: <DepositMoney />,
  },
  {
    faqTitle: "How to Buy Subscription?",
    component: <BuySubscription />,
  },
];
