import React, { useState } from "react";
import PreviewNotification from "./preview/PreviewNotification";
import DetailedNotification from "./details/DetailedNotification";

function Notifications() {
  const [showPreviews, setShowPreviews] = useState(true);
  // We want to either show the preview or the full notification
  const Showing = showPreviews ? (
    <div className="space-y-4">
      <PreviewNotification
        showPreviews={showPreviews}
        setShowPreviews={setShowPreviews}
      />
      <PreviewNotification
        showPreviews={showPreviews}
        setShowPreviews={setShowPreviews}
      />
      <PreviewNotification
        showPreviews={showPreviews}
        setShowPreviews={setShowPreviews}
      />
      <PreviewNotification
        showPreviews={showPreviews}
        setShowPreviews={setShowPreviews}
      />
      <PreviewNotification
        showPreviews={showPreviews}
        setShowPreviews={setShowPreviews}
      />
    </div>
  ) : (
    <DetailedNotification
      showPreviews={showPreviews}
      setShowPreviews={setShowPreviews}
    />
  );

  return <div className="space-y-6 pt-2">{Showing}</div>;
}

export default Notifications;
