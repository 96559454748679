export const userData = {
  accountType: "Reviewer",
  balance: 0,
  working: false,
  subscriptions: [],
  basicSettings: {
    openJobsStrategy: "no_new_tab",
    playAudio: true,
    scrapFirstPagePercentage: 80,
    refreshAfterMin: 5,
    refreshAfterMax: 10,
  },
  advancedSettings: {
    scrapSecondPagePercentage: 20,
    minNoOfJobs: 500,
    maxNoOfJobs: 1000,
    startOpeningJobs: 3,
  },
  basicDefaultSettings: {
    openJobsStrategy: "no_new_tab",
    playAudio: true,
    scrapFirstPagePercentage: 80,
    refreshAfterMin: 5,
    refreshAfterMax: 10,
  },
  advancedDefaultSettings: {
    scrapSecondPagePercentage: 20,
    minNoOfJobs: 500,
    maxNoOfJobs: 1000,
    startOpeningJobs: 3,
  },
};
