import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

function UserDetails({ user, setUser }) {
  const navigate = useNavigate();

  return (
    <div className="p-2 md:w-1/2 mx-auto mt-[20px] space-y-4">
      <div className="flex justify-between">
        {/* User Details on the Left */}
        <div className="space-y-4">
          {/* Verbot Email */}
          <div className="">
            <div>Verbot Email</div>
            <div className="font-bold">
              {user?.email ? user.email : "Email Not Available"}
            </div>
          </div>

          {/* Verbit Email */}
          <div>
            <div>Verbit Email</div>
            <div className="font-bold">
              {user?.verbitEmail
                ? user.verbitEmail
                : "Verbit Email Not Available"}{" "}
            </div>
          </div>

          {/* M-PESA Number */}
          <div>
            <div>M-PESA number</div>
            <div className="font-bold">
              {user?.phone ? user.phone : "M-PESA Number Not Available"}
            </div>
          </div>

          {/* Account Type (Editor/Reviewer) */}
          <div>
            <div>Account Type</div>
            <div className="font-bold">
              {user?.accountType
                ? user.accountType
                : "Account Type Not Available"}
            </div>
          </div>
        </div>

        {/* User Photo on the Right */}
        <div>
          {user?.photoURL && (
            <img src={`${user?.photoURL}`} className="rounded-lg" />
          )}
        </div>
      </div>

      {/* Logout button */}
      <div className="mt-[10px] flex justify-between">
        <div>
          <Button
            variant="contained"
            size="small"
            onClick={() => navigate("/edit")}
            endIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
        </div>

        <div>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setUser(null);
              localStorage.clear();
              navigate("/account");
            }}
            endIcon={<ExitToAppOutlinedIcon />}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
