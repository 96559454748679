import React from "react";
import OpenedJobDetails from "./OpenedJobDetails";

function SessionDetail({ details }) {
  const { name, value } = details;
  return (
    <div className="text-sm md:text-md">
      <div className="flex space-x-2 mx-auto bg-gray-200 px-2">
        <div className="w-1/2 text-left">{name}</div>
        <div className="w-1/2 font-semibold text-right">
          {typeof value == "number" ? value.toLocaleString("en-US") : value}
          {/* Have we opened a job or not */}
          {typeof value == "boolean"
            ? value == true
              ? "True"
              : "False"
            : null}
        </div>
      </div>
      {/* The Opened Job Details */}
      <div className="w-full flex justify-center">
        {typeof value == "boolean" ? (
          value == true ? (
            <div className="w-full mt-2">
              <OpenedJobDetails />
            </div>
          ) : null
        ) : null}
      </div>
    </div>
  );
}

export default SessionDetail;
