import React from "react";
import MenuIcon from "@mui/icons-material/Menu";

function MenuIconComp({ setShowExpandedMenu, activeNavLink }) {
  return (
    <div
      className={`my-auto md:hidden cursor-pointer ${
        activeNavLink !== "Home" ? "text-orange-400" : ""
      }`}
      onClick={() => {
        setShowExpandedMenu((show) => !show);
      }}
    >
      <MenuIcon
        fontSize="medium"
        color={`${activeNavLink !== "Home" ? "warning" : "primary"}`}
      />
    </div>
  );
}

export default MenuIconComp;
