import React, { useState } from "react";
import SubscriptionTypes from "./SubscriptionTypes";
import SubscriptionDetails from "./SubscriptionDetails";
import { Card } from "@mui/material";

function Subscribe() {
  const [selectedSubscription, setSelectedSubscription] = useState("bi_weekly");

  return (
    <div className="py-2" id="subscribe">
      <div className="font-bold md:w-2/3 mx-auto pb-4 px-1 md:px-0">
        Buy Subscription or if user has an active subscription, show
        subscription details
      </div>
      <div className="w-full md:w-2/3 mx-auto px-1 md:px-0">
        <Card elevation={4}>
          <div className="px-1 pt-1 pb-4 flex md:block justify-center space-x-4 md:space-x-0">
            <SubscriptionTypes
              selectedSubscription={selectedSubscription}
              setSelectedSubscription={setSelectedSubscription}
            />
            <SubscriptionDetails selectedSubscription={selectedSubscription} />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Subscribe;
