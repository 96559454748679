import React from "react";
import { Outlet } from "react-router-dom";

function About() {
  return (
    <div className="m-2">
      {/* Nothing to show here, just the nested components */}
      <Outlet />
    </div>
  );
}

export default About;
