import { useState, Suspense, lazy, useEffect } from "react";
import Navbar from "./components/navbar/Navbar";
import ExpandedMenu from "./components/navbar/ExpandedMenu";
import Home from "./components/home/Home";
import Notifications from "./components/notifications/Notifications";
import Settings from "./components/settings/Settings";
import { AnimatePresence } from "framer-motion";
import AccountDetails from "./components/AccountDetails";
import { Route, Routes } from "react-router-dom";
import SubscriptionsPage from "./components/subscriptions/SubscriptionsPage";
import Footer from "./components/footer/Footer";
import { Button } from "@mui/material";
import IntroButtonAnimation from "./components/animations/IntroButtonAnimation";
import MessageModal from "./components/message/MessageModal";
import Fallback from "./components/FallBack";
import SetAccountDetails from "./components/auth/SetAccountDetails";
import Faqs from "./components/faqs/Faqs";
import About from "./components/about/About";
import Developer from "./components/about/Developer";
import Why from "./components/about/Why";
import Audience from "./components/about/Audience";
import Index from "./components/about/Index";
import UserDetails from "./components/auth/UserDetails";
import Account from "./components/auth/Account";
import useActiveNavLink from "./custom_hooks/useActiveNavLink";
import Loading from "./components/auth/Loading";
// import SendMessageStatus from "./components/message/SendMessageStatus";
const SendMessageStatus = lazy(() =>
  import("./components/message/SendMessageStatus")
);

function App() {
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [sent, setSent] = useState();
  const [showExpandedMenu, setShowExpandedMenu] = useState(false);
  const [user, setUser] = useState(null);
  const [activeNavLink, setActiveNavLink] = useActiveNavLink("Home");
  const [loadingUser, setLoadingUser] = useState(false);

  // We want to set the user state to the user stored in the local storage after very refresh.
  // Before this, the user state would be lost since it solely depended on the Account component to set it to the user in local storage
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  return (
    <div>
      <div className="text-center">
        {/* Navbar */}
        <Navbar
          activeNavLink={activeNavLink}
          setActiveNavLink={setActiveNavLink}
          setShowExpandedMenu={setShowExpandedMenu}
          showNavbar={showNavbar}
          user={user}
          setUser={setUser}
        />
      </div>

      {/* Expanded Menu that's only available on mobile phones and other smaller-screened devices */}
      <AnimatePresence initial={false} mode="wait">
        {showExpandedMenu && (
          <ExpandedMenu
            activeNavLink={activeNavLink}
            setActiveNavLink={setActiveNavLink}
            setShowExpandedMenu={setShowExpandedMenu}
            showExpandedMenu={showExpandedMenu}
            user={user}
          />
        )}
      </AnimatePresence>

      {/* Expanded Menu that's only available on mobile phones and other smaller-screened devices */}
      <AnimatePresence initial={false} mode="wait">
        {loadingUser && (
          <Loading
            user={user}
            loadingUser={loadingUser}
            setLoadingUser={setLoadingUser}
          />
        )}
      </AnimatePresence>

      <div className="xl:w-[75%] mt-[42px] md:mt-[50px] mx-auto">
        {/* Everything else apart from the Navbar and Footer will go in here in Rotes */}
        <div className="">
          {/* Account Details */}
          {/* <AccountDetails /> */}
          {/* We split our app in different pages here */}
          <Routes>
            {/* Home Page */}
            <Route path="/" element={<Home />} />
            {/* Settings Page */}
            <Route
              path="/notifications"
              element={<Notifications user={user} setUser={setUser} />}
            />
            {/* Subscriptions Page */}
            <Route
              path="/subscriptions"
              element={<SubscriptionsPage user={user} setUser={setUser} />}
            />
            {/* Settings Page */}
            <Route
              path="/settings"
              element={<Settings user={user} setUser={setUser} />}
            />
            {/* User Account Page */}
            <Route
              path="/account"
              element={
                <Account
                  user={user}
                  setUser={setUser}
                  setActiveNavLink={setActiveNavLink}
                  loadingUser={loadingUser}
                  setLoadingUser={setLoadingUser}
                />
              }
            />
            {/* Edit Account Details Page */}
            <Route
              path="/edit"
              element={
                <SetAccountDetails
                  user={user}
                  setUser={setUser}
                  setActiveNavLink={setActiveNavLink}
                />
              }
            />
            {/* Account Details Page */}
            <Route
              path="/user_details"
              element={<UserDetails user={user} setUser={setUser} />}
            />
            {/* About Us */}
            <Route path="/about" element={<About />}>
              {/* Index About Developer */}
              <Route index element={<Index />} />
              {/* About the Developer */}
              <Route path="developer" element={<Developer />} />
              {/* Why use Verbot */}
              <Route path="why_verbot" element={<Why />} />
              {/* About Verbot */}
              <Route path="about" element={<About />} />
              {/* Target Audience */}
              <Route path="target_audience" element={<Audience />} />
            </Route>
            {/* FAQs Page */}
            <Route path="/faqs/:openedFAQ" element={<Faqs />} />
            {/* Default - If Route doesn't exist */}
            <Route
              path="*"
              element={
                <div className="text-red-400 font-bold">
                  Woza! Kwani umepotea?
                </div>
              }
            />
          </Routes>
        </div>

        <div style={{ marginTop: "56px" }} className="mt-12 sm:mt-20">
          {/* This will be the message component users will use to send us messages */}
          {/* We include a fallback UI using Suspense which will show while component is being imported */}
          {/* Animate Presence will help us animate the exit/unmount behaviour of our component */}

          <AnimatePresence>
            {isMessageOpen && (
              <MessageModal
                isMessageOpen={isMessageOpen}
                setIsMessageOpen={setIsMessageOpen}
                setSent={setSent}
              />
            )}
          </AnimatePresence>

          {/* We include a fallback UI using Suspense which will show while component is being imported */}
          <Suspense fallback={<Fallback height={"30px"} width={"240px"} />}>
            {/* This will inform the user whether their message has been sent or not */}
            <SendMessageStatus sent={sent} setSent={setSent} />
          </Suspense>
        </div>

        {/* The Footer */}
        <Footer setIsMessageOpen={setIsMessageOpen} />
      </div>
    </div>
  );
}

export default App;
