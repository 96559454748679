import React from "react";
import SessionDetail from "./SessionDetail";
import { ourSessionDetails } from "./data";

function SessionDetails() {
  const SessionDetailsComps = ourSessionDetails.map((details) => {
    return <SessionDetail key={details.name} details={details} />;
  });

  return (
    <div className="px-4 space-y-[1px]">
      <div className="flex justify-between mb-1">
        {/* Total Opened Jobs */}
        <div className="">
          <div className="font-thin text-xl md:text-2xl lg:text-3xl text-center text-green-400">
            003
          </div>
          <div className="font-thin text-green-400 text-sm md:text-md">
            Successful Opens
          </div>
        </div>

        {/* Total Jobs Posted */}
        <div className="">
          <div className="font-thin text-xl md:text-2xl lg:text-3xl text-center">
            020
          </div>
          <div className="font-thin text-center text-sm md:text-md">
            Jobs Posted
          </div>
        </div>

        {/* Total Open Job Attempts */}
        <div className="">
          <div className="font-thin text-xl md:text-2xl lg:text-3xl text-center">
            017
          </div>
          <div className="font-thin text-center text-sm md:text-md">
            Failed Opens
          </div>
        </div>
      </div>

      {SessionDetailsComps}
    </div>
  );
}

export default SessionDetails;
