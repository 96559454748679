import React, { useState } from "react";
import SubscriptionType from "./SubscriptionType";

function SubscriptionTypes({ selectedSubscription, setSelectedSubscription }) {
  const subscriptionTypes = ["daily", "weekly", "bi_weekly", "custom"];

  return (
    <div className="md:flex w-1/3 md:w-full mt-4 md:mt-0">
      {subscriptionTypes.map((subscriptionType, index) => (
        <SubscriptionType
          key={index}
          subscriptionType={subscriptionType}
          selectedSubscription={selectedSubscription}
          setSelectedSubscription={setSelectedSubscription}
        />
      ))}
    </div>
  );
}

export default SubscriptionTypes;
