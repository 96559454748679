import { Button } from "@mui/material";
import React from "react";
import Title from "./Title";
import Summary from "./Summary";

function PreviewNotification({ showPreviews, setShowPreviews }) {
  return (
    <div className="mx-2">
      {/* Notification's Title */}
      <Title title="Notification Title" />

      {/* Notification's Summary */}
      <Summary
        summary="      
        The JavaScript map function is a method available on arrays that creates a new 
        array populated with the results of calling a provided function on every element
        in the calling array. The syntax for the map function is: The JavaScript map function
        is a method available on arrays that creates a new array populated with the results 
        of calling a provided function on every element in the calling array. 
        The syntax for the map function is:"
      />
      {/* Read More  */}
      <div className="mt-2">
        <Button
          variant="outlined"
          color="info"
          size="small"
          onClick={() => setShowPreviews((prevValue) => !prevValue)}
        >
          Read More...
        </Button>
      </div>
    </div>
  );
}

export default PreviewNotification;
