import React from "react";
import StartStop from "./StartStop";
import SessionDetails from "./SessionDetails";
import { Card } from "@mui/material";
import Subscribe from "../subscriptions/Subscribe";

function Home() {
  return (
    <div className="w-full">
      <div className="lg:w-3/4 mx-auto">
        {/* Session Details */}
        <div className="w-full mx-auto pb-0 px-1 md:px-0">
          {/* Improving aesthetics by using MUI card component */}
          <Card>
            {/* Session Details */}
            <SessionDetails />
          </Card>
        </div>

        {/* Buy Subscription Component */}
        <div className="w-full mx-auto py-4 px-1 md:px-0">
          <Card>
            <Subscribe />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Home;
