import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { createPortal } from "react-dom";
import ExpandedMenuAnimation from "../animations/ExpandedMenuAnimation";
import { Link } from "react-router-dom";
import { links } from "./data";

function ExpandedMenu({
  user,
  setShowExpandedMenu,
  showExpandedMenu,
  activeNavLink,
  setActiveNavLink,
}) {
  const [accountLinkName, setAccountLinkName] = useState("Account");

  // Updating the Account Page Link Navbar link name
  useEffect(() => {
    if (user !== null) {
      if (user.displayName !== null) {
        // const userEmail = user.email.split("@")[0].slice(0, 5) + "...";
        // setAccountLinkName(userEmail);
        const firstName = user.displayName.split(" ")[0];
        const firstChar = firstName.charAt(0).toUpperCase();
        const username = firstChar + firstName.slice(1);
        setAccountLinkName(username);
      } else {
        setAccountLinkName("Account");
      }
    } else {
      setAccountLinkName("Account");
    }
  }, [user]);

  // Navbar links for mobile users
  const linkComponents = links.map((link, index) => {
    const { LinkIcon, linkURL, linkName } = link;
    return (
      <div
        className={`cursor-pointer my-2 ${
          activeNavLink === linkName ? "text-orange-400 font-bold" : ""
        }`}
        key={index}
      >
        <a
          href={`#${linkName}`}
          onClick={() => {
            setActiveNavLink(linkName);
            setShowExpandedMenu(false);
          }}
        >
          <div className="flex justify-between max-w-[150px] mx-auto">
            <div>{LinkIcon}</div>
            <Link to={`${linkURL}`}>
              {linkName == "Account" ? accountLinkName : linkName}
            </Link>
          </div>
        </a>
      </div>
    );
  });

  if (!showExpandedMenu) return null;
  return createPortal(
    <div
      className="flex flex-col justify-center"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
        opacity: 1,
        backgroundColor: "black",
      }}
    >
      <div>
        {/* We add framer motion animations for more fanciness */}
        <ExpandedMenuAnimation
          showExpandedMenu={showExpandedMenu}
          setShowExpandedMenu={setShowExpandedMenu}
        >
          <div
            className="cursor-pointer text-center mb-4"
            onClick={() => {
              setShowExpandedMenu(false);
            }}
          >
            <CloseIcon fontSize="large" color="primary" />
          </div>

          {/* Our Navbar Links */}
          <div className="text-center text-sky-400">{linkComponents}</div>
        </ExpandedMenuAnimation>
      </div>
    </div>,
    document.querySelector("#expandedMenuPortal")
  );
}

export default ExpandedMenu;
