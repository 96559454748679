import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { faqsData } from "./faqsData";
import { useParams } from "react-router-dom";

export default function Faqs() {
  const [expanded, setExpanded] = React.useState(false);
  const { openedFAQ } = useParams();

  // React.useEffect(() => {
  //   const expanded =
  //     openedFAQ === faqItem.id ? setExpanded(true) : setExpanded(false);
  // }, openedFAQ);

  const faqsAccordions = faqsData.map((faqItem, index) => {
    console.log("Expanded: ", expanded);
    return (
      <Accordion
        index={index}
        expanded={openedFAQ === faqItem.id ? true : false}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{faqItem.faqTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>{faqItem.component}</AccordionDetails>
      </Accordion>
    );
  });
  return (
    <div className="mt-[20px]">
      <div className="mb-[10px]">Frequently Asked Questions (FAQs)</div>
      <div>{faqsAccordions}</div>
    </div>
  );
}
