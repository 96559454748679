import React from "react";
import { footerItems } from "./data";
import { Copyright } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function Footer({ setIsMessageOpen }) {
  const navigate = useNavigate();
  // Handling the clicking of footer links
  const handleFooterLinkClick = (itemLink, linkType) => {
    // We'll use react-router-dom to navigate to internal links and the window to navigate to
    // external links. External links will be opened in a new tab
    linkType === "internal"
      ? navigate(itemLink)
      : // window.open(itemLink, "_self")
        window.open(itemLink, "_blank");
  };

  // Footer Items
  const FooterItems = footerItems.map((footerItem, index) => {
    // Footer Item Items
    const footerItemItems = footerItem.items.map((footerItemItem, index) => {
      // Item Name and Icon Component/Element
      const itemNameAndIcon = (
        <div className="flex space-x-1">
          {/* Item Link */}
          <div>{footerItemItem.itemIcon}</div>
          {/* Item Name */}
          <div className="my-auto">{footerItemItem.itemName}</div>
        </div>
      );

      return (
        <div
          key={index}
          className={`w-1/3 ${
            // We align the last Footer Items Item to the right
            index === footerItem.items.length - 1 ? "flex justify-end" : ""
          } ${
            // We align the middle Footer Items Item to the center
            index === footerItem.items.length - 2 ? "flex justify-center" : ""
          }`}
        >
          <div className="space-x-1 flex">
            <div>
              {/* Message Me Button */}
              {footerItemItem.itemName === "Message Me" ? (
                <div onClick={() => setIsMessageOpen(true)}>
                  {footerItemItem.itemIcon}
                </div>
              ) : (
                // Other Footer Links
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    handleFooterLinkClick(
                      footerItemItem.itemLink,
                      footerItemItem.linkType
                    )
                  }
                >
                  {itemNameAndIcon}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });

    // Footer Items Elements/Components
    return (
      <div key={index} className="mx-2 mb-1 space-y-0">
        {/* Footer Item Name */}
        <div className="text-center font-bold  text-blue-700">
          {footerItem.name}
        </div>
        {/* Footer Item Items */}
        <div className="flex text-blue-700 text-sm md:text-[16px] space-x-1">
          {footerItemItems}
        </div>
        {/* Horizontal Rule for Enhanced Aesthetics */}
        <div className="w-full h-[1px] bg-sky-700 mt-1"></div>
      </div>
    );
  });

  return (
    // The Footer
    <div className="bg-black mb-0">
      <div className="mx-auto">
        <div className="space-y-2 flex justify-center">
          {/* The Footer Items */}
          <div className="pt-1 w-full">{FooterItems}</div>
        </div>
        {/* Copyright */}
        <div className="text-center py-0 pb-1 text-orange-400">
          Copyright <Copyright fontSize="small" /> - Verbot{" "}
          {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
}

export default Footer;
