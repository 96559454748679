import React from "react";

function BuySubscription() {
  return (
    <div className="bg-green-400">
      <div>Buy Subscription</div>
      <div>
        First let's start ith what's a subscription before diving into h0ow to
        buy one. A subscription is what allows you use our bot to find and open
        jobs. Without a subscription, you cannot use our bot, but you can still
        access this website. Subscriptions are flexible; you can buy a daily,
        weekly, bi-weekly or a custom subscription. The price of a subscription
        depends on the duration of the subscription i.e., a weekly subscription
        will be more costly than a daily subscription.
      </div>
      <div>
        To be eligible to buy a subscription, you will be required to frst set
        up your account details. If you have not set up your account details, go
        to the Account page and do so. The only account details we need from you
        to be eligible to buy a subscription are Verbit's email address as well
        as your safaricom M-PESA number. The Verbit email address is used to
        identify your account so that one subscription csn only support one
        Verbit account. Otherwise, you would have been able to use one
        subscription for many Verbit accounts. The Safaricom M-PESA phone number
        is the one which will be used to buy subscription for you. We need this
        number so that we can eliminate the neccessity of requesting for it
        every time you want to buy a subscription.
      </div>
    </div>
  );
}

export default BuySubscription;
