import React from "react";
import { openedJobDetails } from "./data";
import OpenedJobDetail from "./OpenedJobDetail";
import { Button, Card } from "@mui/material";

function OpenedJobDetails() {
  return (
    <div className="mb-2 text-sm md:text-md">
      <Card elevation={4}>
        <div className="w-full flex justify-center px-2">
          <div className="w-full">
            <div className="text-center underline">Opened Job Details</div>
            {/* Here we retun the lenth, pay, total pay, duration, etc. */}
            {openedJobDetails.map((openedJobDetail, index) => {
              return (
                <OpenedJobDetail
                  key={index}
                  openedJobDetail={openedJobDetail}
                />
              );
            })}
            {/* Unassign Job Button */}
            <div className="text-center pt-2 pb-4 max-w-[200px] mx-auto">
              <Button
                variant="contained"
                color="success"
                size="small"
                fullWidth
              >
                UN-ASSIGN
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default OpenedJobDetails;
